.label--amount
{
  font-weight: 500;
  letter-spacing: 1px;
  font-size: rem(15);
  margin: 0 0 1em;
  color: $c_grey_5;
  text-transform: none;
}

.button--amount
{
  padding: 13px 10px;
  // margin: 5px;
  min-width: 0;
  box-shadow: 0 2px 2px rgba(black, 0.12);
  background-color: #fff;
  color: $c_blue_2;
  // border-radius: 4px;
  border: 2px solid transparent;
  font-size: rem(16);
  font-weight: 500;
  letter-spacing: 0.109375rem;
  &::before
  {
    content: none;
  }
  &.selected,
  &:focus,
  &:hover
  {
    background-color: rgba($c_blue_2, 0.12);
    border-color: $c_blue_2;
  }
}

.amount-field
{
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  min-height: 48px;
  // margin-bottom: 1.25rem;
  .amount-field__currency
  {
    flex: 0 1 auto;
    display: flex;
    align-items: center;
    padding: 0 1em;
    line-height: 1;
    font-size: rem(20);
    font-weight: 400;
    color: $c_grey_4;
    border: 1px solid $c_grey_3;
    border-right: none;
    // border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .amount-field__input
  {
    flex: 0 1 auto;
    border: 1px solid $c_grey_3;
    // border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 100%;
    padding: 0 1em;
    font-size: rem(20);
    font-weight: 500;
    color: $c_grey_5;
    text-align: left;
  }
}

// .cover_transaction_fees ~ label
// {
//   // margin-top: 0.25rem;
//   font-size: 1rem;
//   line-height: 1.25;
//   .is-checked { display: none; }
//   .show-for-small-only { display: inline; }
//   .show-for-medium-up { display: none; }
//   @include breakpoint(440px)
//   {
//     .show-for-small-only { display: none; }
//     .show-for-medium-up { display: inline; }
//   }
// }

// .transaction-fees
// {
//   line-height: 1.2;
//   margin-bottom: 0.5em;
// }

@supports(display: grid)
{
  .amount-field
  {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 48px auto;
    grid-template-areas:
    "currency field"
    ". error";
    .amount-field__currency
    {
      grid-area: currency;
    }
    .amount-field__input
    {
      grid-area: field;
    }
    label.error
    {
      grid-area: error;
    }
  }
}

@include breakpoint($medium)
{
  .label--amount
  {
    font-weight: 500;
    letter-spacing: 1px;
    font-size: rem(16);
    // margin: 0 0 2em;
  }
  .amount-field
  {
    min-height: $field-height;
    .amount-field__prefix
    {
      font-size: rem(24);
    }
    .amount-field__input
    {
      font-size: rem(24);
    }
  }
  @supports(display: grid)
  {
    .amount-field
    {
      grid-template-rows: $field-height auto;
    }
  }
}