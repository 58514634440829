.form-heading.progress-steps
{
	max-width: 330px;
	margin: 0 auto;
	width: 100%;
}

.progress
{
	list-style-type: none;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	padding: 0;
	margin: 0 0 30px;
	color: $c_grey_1;
	font-size: rem(16);
	font-weight: 500;
	line-height: rem(28);
	text-align: center;
	position: relative;
	counter-reset: steps;
	&::before,
	&::after
	{
		content: '';
		position: absolute;
		width: 100%;
		top: 50%;
		left: 0;
	}
	&::before
	{
		height: 1px;
		background-color: $c_grey_1;
		z-index: 1;
	}
	&::after
	{
		height: 2px;
		transform: translateY(-50%);
		background-image: linear-gradient(to right, transparent 0%, transparent 50%, transparent 50%);
		z-index: 2;
	}
	.icon--check
	{
		fill: #fff;
	}
	// Step 1
	&[data-progress="1"]
	{
		li:nth-child(1)
		{
			background-color: $c_blue_2;
			color: white;
		}
	}
	// Step 2
	&[data-progress="2"]
	{
		&::after
		{
			background-image: linear-gradient(to right, $c_blue_1 0%, $c_blue_1 50%, transparent 50%);
		}
		li:nth-child(1)
		{
			background-color: $c_blue_1;
			span
			{
				display: none;
			}
			.icon
			{
				display: inline-block;
				vertical-align: -3px;
			}
		}
		li:nth-child(2)
		{
			background-color: $c_blue_2;
			color: white;
		}
	}
	// Step 3
	&[data-progress="3"]
	{
		&::after
		{
			background-image: linear-gradient(to right, $c_blue_1 0%, $c_blue_1 100%);
		}
		li:nth-child(1),
		li:nth-child(2)
		{
			background-color: $c_blue_1;
			span
			{
				display: none;
			}
			.icon
			{
				display: inline-block;
				vertical-align: -3px;
			}
		}
		li:nth-child(3)
		{
			background-color: $c_blue_2;
			color: white;
		}
	}
	// Complete
	&[data-progress="complete"]
	{
		&::after
		{
			background-image: linear-gradient(to right, #fff 0%, #fff 100%);
		}
		li
		{
			color: $c_blue_1;
			span
			{
				display: none;
			}
			.icon
			{
				display: inline-block;
				vertical-align: -3px;
				fill: $c_grey_1;
			}
		}
	}
	li
	{
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: 1;
		width: 26px;
		height: 26px;
		background-color: #fff;
		box-shadow: 0 1px 2px rgba(black, 0.23);
		border-radius: 50%;
		z-index: 3;
		.icon
		{
			display: none;
		}
	}
}