.field,
.field--col
{
	position: relative;
}

.form-page
{
	// &:target
	// {
	//   animation: highlight 5s ease;
	// }
	&.inactive
	{
		display: none;
	}
	&.active
	{
		display: block;
	}
	// #form-page--4
	// {
	//   min-height: 480px;
	// }
}

.form-nav // <footer>
{
	margin-top: 1em;
}

@keyframes highlight
{
	0%   { background-color: rgba(#ffff00, 0.5); }
	50%  { background-color: rgba(#ffff00, 0.5); }
	100% { background-color: rgba(#ffff00, 0.0); }
}

.required label:not([class="error"])::after
{
	content: '*';
}

.label--float
{
	@include float-label(13px, 16px, 0, 0);
}

.jsAchPopup
{
	display: none;
	&.active
	{
		display: block;
	}
}

// Wrapper/Container for Stripe CC element
#card-element
{
	background-color: #fff;
}

.update-types
{
	margin-top: 1em;
	margin-left: 2em;
	border-radius: 3px;
	background-color: $c_offwhite_2;
	padding: 1em;
	> *:first-child
	{
		margin-top: 0;
	}
	> *:last-child
	{
		margin-bottom: 0;
	}
}