// .amt-opts
// {
// 	position: relative;
// }
// .amt-opts__annually
// {
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// }
.radio-group
{
	margin-top: 1.5em;
}
.payment-method-desc
{
	line-height: 1;
	font-size: 16px;
	color: #797c8b;
}
.donation-form .radio-group p.payment-method-desc
{
	margin-top: 0.25em;
	margin-bottom: 0;
}
.checkbox-transaction-fees
{
}
.button-group
{
	// display: flex;
	// flex-flow: row wrap;
	// align-items: center;
	// margin: 0 -5px;
	display: grid;
	grid-template-columns: repeat( auto-fill, 1fr);
	gap: 15px;
	&.-gap
	{
		gap: 5px;
	}
	&.-rev
	{
		flex-direction: row-reverse;
	}
	&.-nowrap
	{
		flex-wrap: nowrap;
	}
	&.footer
	{
		margin-top: 2rem;
		width: 100%;
	}
	&.expanded
	{
		align-items: stretch;
		justify-content: stretch;
	}
	&.left
	{
		justify-content: flex-start;
	}
	&.center
	{
		justify-content: center;
	}
	&.right
	{
		justify-content: flex-end;
	}
	&.button--amounts
	{
		--suggestedAmountColumns: 1;
		display: grid;
		grid-template-columns: repeat( var(--suggestedAmountColumns), 1fr);
		grid-auto-rows: auto;
		// grid-gap: 5px;
		&[hidden]
		{
			display: none;
		}
		&[data-count="2"]
		{
			--suggestedAmountColumns: 2;
		}
		&[data-count="3"]
		{
			--suggestedAmountColumns: 1;
			@media (min-width: 400px) {
				--suggestedAmountColumns: 3;
			}

		}
		&[data-count="4"]
		{
			--suggestedAmountColumns: 2;
			@media (min-width: 480px) {
				--suggestedAmountColumns: 4;
			}
		}
		// &.-monthly
		// {
		// 	display: none;
		// }
/*
		@media (min-width: 340px) {
			--suggestedAmountColumns: 2;
		}
		@media (min-width: 480px) {
			--suggestedAmountColumns: 4;
		}
*/
	}
	.button
	{
		flex: 1 1 auto;
		// margin: 5px;
		order: 1;
		&.hollow
		{
			order: 2;
		}
	}
	@include breakpoint($medium)
	{
		justify-content: space-around;
		&.expanded
		{
			.button
			{
				flex: 1 1 0;
			}
		}
		.button
		{
			flex: 0 1 auto;
		}
	}
}