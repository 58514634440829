.form-page--3
{
	.form__two-buttons
	{
		display: flex;
		gap: 0.5em;
		flex-flow: row nowrap;
		justify-content: space-between;
		margin-top: 2rem;
		.button
		{
			padding: 14px 5px;
			flex: 1 1 50%;
			min-width: unset;
		}
	}
}