// Colors
$c_blue_1: #0079c1;
$c_blue_2: #0A6DB6;

// white colors
$c_white_1: #fff;
$c_white_2: #fafafa;

$c_offwhite_1: #f5f5f5;
$c_offwhite_2: #edecef;

$c_grey_1: #d4d3d8;
$c_grey_2: #acb1bb;
$c_grey_3: #979797;
$c_grey_4: #797c8b;
$c_grey_5: #4b4d59;
$c_grey_6: #353439;

$c_yellow_1: #f8c050;

$c_orange_1: #fe7000;
$c_orange_2: #ff5800;

$c_red_1: #f44c4c;

// Screen sizes
$small: 320px;
$medium: 640px;
$large: 1024px;
$xlarge: 1200px;
$xxlarge: 1440px;
$retina: min-resolution 1.5dppx;

$button-padding--vertical: 16px;
$button-padding--horizontal: 30px;
$button-border-width: 2px;

$field-height: 57px;

$global-spacing: 32px;

$textual-inputs: "input[type='text'], input[type='password'], input[type='date'], input[type='datetime'], input[type='datetime-local'], input[type='month'], input[type='week'], input[type='email'], input[type='number'], input[type='search'], input[type='tel'], input[type='time'], input[type='url'], textarea";