.donation-note
{
	margin-top: 0.8em;
	p
	{
		text-transform: uppercase;
		font-size: 14px;
		color: #ADB1BC;
		letter-spacing: 1.5px;
		font-weight: 400;
		text-align: left;
		margin-top: 0;
		strong,b
		{
			font-weight: 500;
		}
	}
}