#form-page--3
{
  .donation-summary
  {
    // margin-bottom: 1.5em;
  }
}

.donation-summary
{
  // width: 100%;
  // border-collapse: collapse;
  // margin: 1em auto;
  // border: 1px solid #ddd;
  // background-color: #eee;
  // box-shadow: 0 2px 10px rgba(black, 0.23);
  // padding: 1em;
  // text-transform: uppercase;
  // letter-spacing: 0.05em;
  // margin-bottom: 0.9em;
  p
  {
    font-size: 16px;
    margin-top: 0.5em;
    margin-bottom: 0;
    color: #797C8B;
  }
  &__total
  {
    th,
    td
    {
      border-top-color: #000;
    }
  }
  caption
  {
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: $c_blue_1;
  }
  th,
  td
  {
    border: 1px solid #ddd;
    padding: 5px 10px;
  }
  th
  {
    text-align: right;
    width: 100%;
    color: $c_grey_5;
  }
  td
  {
    font-weight: 900;
    text-align: right;
    padding-left: 10px;
  }
  .is-hidden
  {
    display: none;
  }
}