.payment-method
{
	font-size: 13px;
	// Radio Group button styling
	// padding-bottom: 1.5em;
	p {
		margin: 1em 0 0.25em;
	}
	.field--radio-group {
		width: 100%;
		margin-top: 0;
		display: flex;
		flex-direction: row;
		// justify-content: space-evenly;
		justify-content: center;
	}
	.input--radio
	{
		// width: 24%;
		position: relative;
		display: flex;
		gap: 5px;
		align-items: center;
		text-align: center;
		// display: grid;
		// grid-template-areas: "label" "radio";
		// gap: 0.5em;
		// justify-items: center;
		// height: 100px;
		&[hidden] { display: none; }
		input[type="radio"]
		{
			visibility: hidden;
			height: 0;
			width: 0;
			// grid-area: radio;
			+ label
			{
				// grid-area: label;
				border: 2px solid transparent;
				padding: 0.5em;
				display: flex;
				flex-flow: column nowrap;
				align-items: center;
				justify-content: flex-end;
				gap: 0.5em;
				transition: 0.2s ease all;
				img
				{
					display: block;
					// width: 100%;
					width: 75px;
					height: 75px;
					object-fit: contain;
				}
				span
				{
					font-weight: bold;
					width: min-content;
					// min-width: 100%;
				}
			}
			&:checked
			{
				+ label
				{
					border-color: #0079c1;
					background-color: rgba($c_blue_1, 0.2);
				}
			}
		}
	}
}
