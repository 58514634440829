/* Convert to rem's */
@function rem($pixels)
{
	@return ( $pixels / 16 ) * 1rem;
}

// Breakout full-width element from limited-width parents
@mixin full-width()
{
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

/*
 * Floating form label
 * Form labels for input fields that appear over the input field. When
 * the latter received focus, the label shrinks and moves above the input.
 */
@mixin float-label($label-font-size: 13px, $field-font-size: 16px, $padding-v: 0, $padding-h: 0)
{
	display: flex;
	flex-flow: column-reverse nowrap;
	justify-content: flex-end;
	input,
	label:not([class="error"]),
	textarea
	{
		line-height: 1;
		transition: all 0.2s;
	}
	label:not([class="error"])
	{
		order: 3;
	}
	label.error
	{
		order: 1;
	}
	input,
	textarea
	{
		order: 2;
		font-size: $field-font-size;
		padding: $padding-v $padding-h $padding-v;
	}
	label:not([class="error"])
	{
		font-size: $label-font-size/*  * 0.8 */;
	}
	input:placeholder-shown + label:not([class="error"]),
	textarea:placeholder-shown + label:not([class="error"])
	{
		// max-width: 75%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		pointer-events: none;
		// cursor: text;
		transform-origin: left bottom;
		transform: translate($padding-h, $padding-v+$field-font-size)/*  scale(1.25) */;
	}
	::placeholder
	{
		opacity: 0;
		transition: inherit;
	}
	input:focus::placeholder,
	textarea:focus::placeholder
	{
		opacity: 1;
	}
	input:not(:placeholder-shown) + label:not([class="error"]),
	input:focus + label:not([class="error"]),
	textarea:not(:placeholder-shown) + label:not([class="error"]),
	textarea:focus + label:not([class="error"])
	{
		transform: translate(0,-0.25em) scale(1);
		cursor: pointer;
		pointer-events: auto;
	}
}