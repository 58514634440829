.o-donationForm__heading
{
  font-weight: 500;
  // margin-bottom: 20px;
  margin-bottom: 0;
  font-size: rem(20);
  line-height: 1.15;
  letter-spacing: 0.35px;
}

.o-donationForm__content
{
  color: $c_grey_5;
  p
  {
    font-size: 0.95em;
    line-height: 1.5;
    margin: 0;
    + p
    {
      margin-top: 0.5em;
    }
  }
}

@include breakpoint($medium)
{
  .form-heading
  {
    // margin: 0 0 2em;
    margin: 0 0;
  }
  .o-donationForm__heading
  {
    font-size: rem(24);
    margin-bottom: 10px;
  }
  .o-donationForm__content
  {
    p
    {
      font-size: 0.95em;
      + p
      {
        margin-top: 1em;
      }
    }
  }
}
