.button
{
	appearance: none;
	background-color: $c_blue_1;
	// border-radius: 4px;
	border-radius: 0;
	border: none;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	font-family: inherit;
	font-size: rem(13);
	font-weight: 600;
	letter-spacing: 2px;
	line-height: 1.2308;
	min-width: 170px;
	overflow: hidden;
	padding: $button-padding--vertical $button-padding--horizontal;
	position: relative;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	transition: .3s ease all;
	&::before
	{
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 100%;
		width: 100%;
		height: 100%;
		background-color: rgba(white, 0.1);
		z-index: 1;
		transition: right 0.4s ease;
		pointer-events: none;
	}
	&:hover,
	&:focus
	{
		background-color: lighten($c_blue_1, 4%);
		&::before
		{
			right: 0;
		}
	}
	&[disabled]
	{
		opacity: 0.5;
		cursor: not-allowed;
		color: rgba(#fff, 0.5);
	}
	&.red
	{
		background-color: $c_red_1;
	}
	&.orange
	{
		background-color: $c_orange_1;
	}
	&.hollow
	{
		background-color: transparent;
		border: 2px solid $c_blue_1;
		padding: $button-padding--vertical - $button-border-width $button-padding--horizontal - $button-border-width;
		color: $c_blue_1;
		&:hover
		{
			background-color: $c_blue_1;
			color: #fff;
		}
		&.red
		{
			border-color: $c_red_1;
			color: $c_red_1;
		}
		&.orange
		{
			border-color: $c_orange_1;
			color: $c_orange_1;
		}
	}
	&.center
	{
		display: inline-block;
		margin-left: auto;
		margin-right: auto;
	}

	&.button--donate
	{
		background-color: $c_red_1;
		&:hover,
		&:focus
		{
			background-color: lighten($c_red_1, 4%);
		}
		&.hollow
		{
			border: 2px solid $c_red_1;
			color: $c_red_1;
			&:hover
			{
				background-color: $c_red_1;
			}
		}
	}
	.icon
	{
		vertical-align: -5px;
		width: 1.5em;
		height: 1.25em;
		&--pre
		{
			margin-right: 1em;
		}
		&--post
		{
			margin-left: 1em;
		}
	}

	@include breakpoint($medium)
	{
		padding: 16px 30px;
	}
}